import React from "react";
import DailyRates from "../../components/DailyRates";
import { connect } from "react-redux";
import { uploadDailyRates } from "../../Redux/Actions/DailyRates";
import { ProgressBar } from "primereact/progressbar";
import { EzToast } from "../../common/EzToast";
import { getToastProps } from "../../utils/common";

const DailyRatesScreen = (props) => {
  const {
    uploadDailyRates,
    isLoading,
    isUploadDailyRatesSuccess,
    isUploadDailyRatesError,
  } = props;

  const dailyRatesProps = {
    uploadDailyRates,
    isLoading,
  };

  return (
    <div>
      {isLoading && (
        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
      )}
      <EzToast
        {...getToastProps(
          isUploadDailyRatesSuccess,
          isUploadDailyRatesError,
          "Upload daily rates successfully!",
          "Error while uploading daily rates."
        )}
      />
      <DailyRates {...dailyRatesProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadDailyRates: (payload) => dispatch(uploadDailyRates(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isLoading: selectFromDailyRatesReducer(state, "isLoading"),
    isUploadDailyRatesSuccess: selectFromDailyRatesReducer(state, "isUploadDailyRatesSuccess"),
    isUploadDailyRatesError: selectFromDailyRatesReducer(state, "isUploadDailyRatesError"),
  };
};

const selectFromDailyRatesReducer = (state, path) => {
  return selectDailyRatesReducer(state)[path];
};

const selectDailyRatesReducer = ({ dailyRatesReducer }) => {
  return dailyRatesReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyRatesScreen);
