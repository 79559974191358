import React, { useEffect } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import Transactions from "../../components/Transactions";
import transactionsData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchTransactions,
  exportTransaction,
  initializeTransactionScreen,
  importTransaction,
} from "../../Redux/Actions/transactions";
import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";
import { EzToast } from "../../common/EzToast";
import { getToastProps } from "../../utils/common";

const TransactionsScreen = (props) => {
  const {
    fetchTransactions,
    history,
    initTransactionScreen,
    showPageLevelLoader,
    transactionsListView,
    isTransactionExportError,
    isTransactionExportSuccess,
    isImportTransactionSuccess,
    isImportTransactionError,
    paginationInfo,
    isLoading,
    exportTransaction,
    screenPermissions,
    importTransaction,
  } = props;
  // loader none for now------------
  setTimeout(() => loadingsShow("none"), 1000);
  const loadLazyData = (props) => {
    fetchTransactions(props);
  };
  const createTransaction = () => {
    history.push(APP_URLS.TRANSACTIONS.ADD_TRANSACTION);
  };

  const viewTransaction = (transactionId) => {
    const id = transactionId?.authentication_id;
    const path = generatePath(APP_URLS.TRANSACTIONS.VIEW_TRANSACTION, {
      id,
    });
    history.push(path);
  };

  const editTransaction = (transactionId) => {
    const id = transactionId?.id;
    const path = generatePath(APP_URLS.TRANSACTIONS.EDIT_TRANSACTION, {
      id,
    });
    history.push(path);
  };
  useEffect(() => {
    initTransactionScreen();
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionProps = {
    createTransaction,
    editTransaction,
    viewTransaction,
    importTransaction,
    ...transactionsData,
    screenPermissions,
    exportTransaction: exportTransaction,
    isTransactionExportError,
    isTransactionExportSuccess,
    fetchTransactions: loadLazyData,
    ...transactionsData,
    tableData: {
      ...transactionsData.tableData,
      columns: transactionsListView?.columns,
      subcolumns: transactionsListView?.subcolumns,
      rows: transactionsListView?.rows,
    },
    paginationInfo: {
      ...transactionsData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalPages,
    },
    isLoading,
  };
  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");

  return (
    <div>
      {isLoading && (
        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
      )}
      <EzToast
        {...getToastProps(
          isImportTransactionSuccess,
          isImportTransactionError,
          "Import transactions successfully!",
          "Error while Importing transactions."
        )}
      />
      <Transactions {...transactionProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initTransactionScreen: () => dispatch(initializeTransactionScreen()),
    fetchTransactions: (payload) => dispatch(fetchTransactions(payload)),
    exportTransaction: (payload) => dispatch(exportTransaction(payload)),
    importTransaction: (payload) => dispatch(importTransaction(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    transactionsListView: selectTransactionList(state.transactionReducer),
    showPageLevelLoader: selectIsLoadingPage(state.transactionReducer),
    paginationInfo: selectPaginationInfo(state.transactionReducer),
    isPageLevelError: selectIsPageLevelError(state.transactionReducer),
    isLoading: selectIsLoading(state.transactionReducer),

    isTransactionExportError: selectIsTransactionExportError(
      state.transactionReducer
    ),
    isTransactionExportSuccess: selectIsTransactionExportSuccess(
      state.transactionReducer
    ),
    isImportTransactionSuccess: selectFromTransactionReducer(
      state,
      "isImportTransactionSuccess"
    ),
    isImportTransactionError: selectFromTransactionReducer(
      state,
      "isImportTransactionError"
    ),
  };
};

const selectIsTransactionExportError = (transactionReducer) =>
  transactionReducer.isTransactionExportError;
const selectIsTransactionExportSuccess = (transactionReducer) =>
  transactionReducer.isTransactionExportSuccess;

const selectIsLoading = (transactionReducer) => transactionReducer.isLoading;
const selectPaginationInfo = (transactionReducer) =>
  transactionReducer.paginationInfo;
const selectIsLoadingPage = (transactionReducer) =>
  transactionReducer.isLoadingPage;
const selectIsPageLevelError = (transactionReducer) =>
  transactionReducer.isPageLevelError;
const selectTransactionList = (transactionReducer) =>
  transactionReducer.transactionsListView;

const selectFromTransactionReducer = (state, path) => {
  return selectTransactionReducer(state)[path];
};

const selectTransactionReducer = ({ transactionReducer }) => {
  return transactionReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsScreen);
