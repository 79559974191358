export const FORM_FIELDS_NAME = {
  INVOICE_NUMBER: "invoice_number",
  COMPANY: "company_name",
  DATE_ISSUED: "issued_date",
  DATE_DUE: "due_date",
  START_DATE: "start_date",
  END_DATE: "end_date",
  STATUS: "status",
  TOTAL: "total_amount",
  AMOUNT_DUE: "amount_due",
  CURRENCY: "currency",
  FILE_NAME: "file_name"
};
