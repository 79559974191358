import Cookies from "universal-cookie";
import { API_URL } from "../../../constants/urlConstants";
import { baseUrl } from "../../PostAPI";
import { post, put } from "axios";
import { APIError, handleAPIError } from "../../common/errorHandler";
const cookies = new Cookies();

export const uploadDailyRates = async (payload) => {
  const { type, file } = payload;
  const url = `${baseUrl}${API_URL.DAILY_RATES.UPLOAD_DAILY_RATES}?type=${type}`;

  try {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("authorization"),
      },
    };

    let result = await post(url, formData, config);

    if (result?.error) {
      throw new APIError(result);
    }

    return result;
  } catch (error) {
    console.error("Error uploading daily rates:", error);
    return handleAPIError(error);
  }
};

// Helper function to handle API requests (like POST and PUT for other actions)
const dataUploadApi = (url, data, isEdit = false) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: cookies.get("authorization"),
    },
  };

  if (isEdit) {
    return put(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
