import React from "react";
import CreateCompanyComponent from "../../../components/Company/CreateCompany";

const CreateCompany = () => {
  return (
    <CreateCompanyComponent />
  )
};

export default CreateCompany;
