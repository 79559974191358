import {
  TOAST,
  UPLOAD_DAILY_RATES,
} from "../../../constants/action-types/dailyRates";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  isUploadDailyRatesSuccess: false,
  isUploadDailyRatesError: false,
};

export const dailyRatesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_DAILY_RATES.START:
      return { ...state, isLoading: true };

    case UPLOAD_DAILY_RATES.ERROR: {
      return {
        ...state,
        isLoading: false,
        isUploadDailyRatesSuccess: false,
        isUploadDailyRatesError: true,
      };
    }
    case UPLOAD_DAILY_RATES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isUploadDailyRatesSuccess: true,
        isUploadDailyRatesError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isUploadDailyRatesSuccess: false,
        isUploadDailyRatesError: false,
      };
    }
    default:
      return state;
  }
};
