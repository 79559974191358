import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Container, Row } from "react-bootstrap";
import { getFormBaseValidationRule } from "../../utils/form/form-utils";
import EzControlledDropDowntWithFloatingLabel from "../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import { Divider } from "primereact/divider";

const fuelProviders = [
  { key: "TA-Petro", value: "TA-Petro" },
  { key: "Comdata", value: "Comdata" },
  { key: "Esso", value: "Esso" },
  { key: "Suncor", value: "Suncor" },
];

const DailyRates = (props) => {
  const { uploadDailyRates } = props;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileUploadRef = useRef(null);

  const onSubmit = (data) => {
    if (!selectedFile) {
      setError("file", { type: "manual", message: "File is required" });
      return;
    }
    const payload = {
      type: data.fuelProvider,
      file: selectedFile,
    };
    uploadDailyRates(payload);
    handleCancel();
  };

  const handleCancel = () => {
    reset();
    setSelectedFile(null);
    clearErrors("file");
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div className="paddingbox">
      <div className="containerbox">
        <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
          <SectionHeader label="Upload Daily Rates" />

          <Container fluid className="card-box">
            <div className="my-3 w-50">
              <EzControlledDropDowntWithFloatingLabel
                name="fuelProvider"
                rules={
                  getFormBaseValidationRule().DEFAULT.VALIDATION_RULE_MESSAGE
                }
                control={control}
                optionLabel="value"
                optionValue="key"
                options={fuelProviders}
                label="Select the fuel provider"
                isError={errors["fuelProvider"]}
                errorMsg={getFormErrorMessage("fuelProvider")}
              />
            </div>

            <div className="my-3 w-50">
              <label className="form-label">Upload File</label>
              <FileUpload
                ref={fileUploadRef}
                mode="basic"
                customUpload
                maxFileSize={1000000}
                accept=".xls,.xlsx"
                chooseLabel="Select File"
                onSelect={(e) => {
                  if (e.files && e.files.length > 0) {
                    setSelectedFile(e.files[0]);
                    clearErrors("file");
                  }
                }}
              />
              {errors.file && (
                <small className="p-error">{errors.file.message}</small>
              )}
            </div>

            <div className="d-flex justify-content-end mt-3">
              <Button
                label="Cancel"
                type="button"
                className="p-btn-danger p-button-outlined mr-4"
                onClick={handleCancel}
                style={{ width: "100px" }}
              />
              <Button
                type="submit"
                label="Send"
                className="p-button-raised"
                style={{ width: "100px" }}
              />
            </div>
          </Container>
        </form>
      </div>
    </div>
  );
};

const SectionHeader = ({ label }) => (
  <Row>
    <div className="d-flex w-100">
      <b className="ml-2">{label || ""}</b>
    </div>
    <Divider align="top" type="solid" />
  </Row>
);

export default DailyRates;
