import React from "react";
import { Route } from "react-router-dom";
import CompanyScreen from "../../containers/CompanyScreen";
import { APP_URLS } from "../../constants/urlConstants";
import CreateCompany from "../../containers/CompanyScreen/CreateCompany";

const ComapnyRoutes = (props) => {
  const { access, match } = props;
  let { create, read, update = false } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <CompanyScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}

      {!!create && (
        <Route
          path={APP_URLS.COMPANY.ADD_COMPANY}
          component={CreateCompany}
        />
      )}
    </>
  );
};

export default ComapnyRoutes;
