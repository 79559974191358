import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
    INIT_TRANSACTIONS,
    FETCH_TRANSACTIONS_LIST,
    CREATE_TRANSACTIONS_RECORD,
    EDIT_TRANSACTIONS_RECORD,
    EXPORT_TRANSACTIONS,
    TOAST,
    FETCH_TRANSACTIONS_DETAIL,
    IMPORT_TRANSACTIONS
} from '../../../constants/action-types/transactions';
import { transactionService } from '../../../Services';
import { goBack } from '../../../utils/gotoback';

export const initializeTransactionScreen = () => ({ type: INIT_TRANSACTIONS });

export const transactionsFetchStart = (payload) => ({
  type: FETCH_TRANSACTIONS_LIST.START,
  payload,
});

export const transactionsFetchSuccess = (payload) => ({
  type:FETCH_TRANSACTIONS_LIST.SUCCESS,
  payload,
});

export const transactionsFetchError = (payload) => ({
  type: FETCH_TRANSACTIONS_LIST.ERROR,
  payload,
});

export const fetchTransactions = (payload) => {
  return (dispatch) => {
    dispatch(transactionsFetchStart(payload));

    transactionService.fetchTransactions(payload).then((data) => {
      if (!data.isError) {
        dispatch(transactionsFetchSuccess(data));
      
      } else {
        dispatch(transactionsFetchError(data));
      }
    });
  };
};



export const createTransactionRecordStart = () => ({
    type: CREATE_TRANSACTIONS_RECORD.START,
  });
  
  export const createTransactionRecordSuccess = (payload) => ({
    type: CREATE_TRANSACTIONS_RECORD.SUCCESS,
    payload,
  });
  
  export const createTransactionRecordError = (payload) => ({
    type: CREATE_TRANSACTIONS_RECORD.ERROR,
    payload,
  });
  
  export const resetToast = (payload) => ({
    type: TOAST.RESET,
    payload,
  });
  
  export const createTransaction = (payload) => {
    return (dispatch) => {
      dispatch(createTransactionRecordStart());
      transactionService.createTransaction(payload).then((data) => {
        if (!data.isError) {
          dispatch(createTransactionRecordSuccess());
          goBack()
        } else {
          dispatch(createTransactionRecordError(data));
        }
        setTimeout(() => {
          dispatch(resetToast());
        }, DEFAULT_TOAST_LIFE + 1000);
      });
    };
  };
  
  export const editTransactionRecordStart = () => ({
    type: EDIT_TRANSACTIONS_RECORD.START,
  });
  
  export const editTransactionRecordSuccess = (payload) => ({
    type: EDIT_TRANSACTIONS_RECORD.SUCCESS,
    payload,
  });
  
  export const editTransactionRecordError = (payload) => ({
    type: EDIT_TRANSACTIONS_RECORD.ERROR,
    payload,
  });
  
  export const editTransaction = (payload) => {
    return (dispatch) => {
      dispatch(editTransactionRecordStart());
      transactionService.editTransaction(payload).then((data) => {
        if (!data.isError) {
          dispatch(editTransactionRecordSuccess());
          goBack()
        } else {
          dispatch(editTransactionRecordError(data));
        }
        setTimeout(() => {
          dispatch(resetToast());
        }, DEFAULT_TOAST_LIFE + 1000);
      });
    };
  };
  
  
 // Export Transaction

export const exportTransactionRecordStart = (payload) => ({
  type: EXPORT_TRANSACTIONS.START,
  payload
});

export const exportTransactionRecordSuccess = (payload) => ({
  type: EXPORT_TRANSACTIONS.SUCCESS,
  payload,
});

export const exportTransactionRecordError = (payload) => ({
  type: EXPORT_TRANSACTIONS.ERROR,
  payload,
});

export const exportTransaction = (payload) => {
  return (dispatch) => {
    dispatch(exportTransactionRecordStart());
    transactionService.transactionService(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportTransactionRecordSuccess(data));
      } else {
        dispatch(exportTransactionRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


export const transactionDetailFetchStart = (payload) => ({
  type: FETCH_TRANSACTIONS_DETAIL.START,
  payload,
});

export const transactionDetailFetchSuccess = (payload) => ({
  type: FETCH_TRANSACTIONS_DETAIL.SUCCESS,
  payload,
});

export const transactionDetailFetchError = (payload) => ({
  type: FETCH_TRANSACTIONS_DETAIL.ERROR,
  payload,
});

export const fetchTransactionById = (payload) => {
  return (dispatch) => {
    dispatch(transactionDetailFetchStart(payload));
    transactionService
      .fetchTransactionsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(transactionDetailFetchSuccess(data));
        } else {
          dispatch(transactionDetailFetchError(data));
        }
      });
  };
};

// Import transactions
export const importTransactionStart = () => ({
  type: IMPORT_TRANSACTIONS.START,
});

export const importTransactionSuccess = (payload) => ({
  type: IMPORT_TRANSACTIONS.SUCCESS,
  payload,
});

export const importTransactionError = (payload) => ({
  type: IMPORT_TRANSACTIONS.ERROR,
  payload,
});

export const importTransaction = (payload) => {
  return (dispatch) => {
    dispatch(importTransactionStart());
    transactionService.importTransaction(payload).then((data) => {
      if (!data.isError) {
        dispatch(importTransactionSuccess(data));
      } else {
        dispatch(importTransactionError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};