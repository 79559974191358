import {
  FETCH_COMPANY_DETAIL,
  INIT_COMPANY,
  FETCH_COMPANY_LIST_START,
  FETCH_COMPANY_LIST_SUCCESS,
  FETCH_COMPANY_LIST_ERROR,
  TOAST,
  FETCH_CUSTOMER_LIST_START,
  FETCH_CUSTOMER_LIST_SUCCESS,
  FETCH_CUSTOMER_LIST_ERROR,
} from "../../../constants/action-types/company";

const formFieldValueMap = {
  id: null,
  code: null,
  name: null,
  profile_img: null,
};

const INITIAL_STATE = {
  companyListView: [],
  customerListView: [],
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCompanySuccess: false,
  isCompanyError: false,
  isCompanySuccess: false,
  isCompanyError: false,
};

export const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_COMPANY:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_COMPANY_LIST_START:
    case FETCH_CUSTOMER_LIST_START:
      return { ...state, isLoading: true };

    case FETCH_COMPANY_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        companyListView: [],
        paginationCompanyInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
      };

      if (!content) {
        return resetState;
      }
      const {
        number,
        size,
        total_elements: totalElements,
        total_pages: total_pages,
      } = page;
      return {
        ...state,
        ...resetState,
        companyListView: content,
        paginationCompanyInfo: {
          pageSize: 1,
          totalRecords: 1,
          totalPages: 1,
          pageNumber: 1,
        },
      };
    }

    case FETCH_COMPANY_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        companyListView: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
      };
    }

    case FETCH_CUSTOMER_LIST_SUCCESS: {
      const { page, content } = action.payload;

      let resetState = {
        customerListView: [],
        paginationCustomerInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
      };

      if (!content) {
        return resetState;
      }

      return {
        ...state,
        ...resetState,
        customerListView: content,
        paginationCustomerInfo: {
          pageSize: page.size,
          totalRecords: page.total_elements,
          totalPages: page.total_pages,
          pageNumber: page.number,
        },
      };
    }

    case FETCH_CUSTOMER_LIST_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        customerListView: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
      };
    }
    default:
      return state;
  }
};
