import React from "react";
import { Route } from "react-router-dom";

import { APP_URLS } from "../../constants/urlConstants";
import TransactionsScreen from "../../containers/TransactionsScreen";
import CreateTransactions from "../../containers/TransactionsScreen/CreateTransactions";

const TransactionRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update = false } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <TransactionsScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.TRANSACTIONS.ADD_TRANSACTION}
          component={CreateTransactions}
          screenPermissions={access}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.TRANSACTIONS.EDIT_TRANSACTION}
          render={(routeProps) => (
            <CreateTransactions
              {...routeProps}
              isEdit={true}
              screenPermissions={access}
            />
          )}
        />
      )}
      {!!read && (
        <Route
          path={APP_URLS.TRANSACTIONS.VIEW_TRANSACTION}
          render={(routeProps) => (
            <CreateTransactions
              {...routeProps}
              isView={true}
              screenPermissions={access}
            />
          )}
        />
      )}
    </>
  );
};

export default TransactionRoutes;
