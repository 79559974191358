export const INIT_TRANSACTIONS = 'INIT_TRANSACTIONS';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_TRANSACTIONS_SCREEN =
  'INIT_CREATE_NEW_TRANSACTIONS_SCREEN';

  export const DELETE_TRANSACTIONS_RECORD = {
    START: 'DELETE_TRANSACTIONS_RECORD_START',
    SUCCESS: 'DELETE_TRANSACTIONS_RECORD_SUCCESS',
    ERROR: 'DELETE_TRANSACTIONS_RECORD_ERROR',
  };
export const CREATE_TRANSACTIONS_RECORD = {
  START: 'CREATE_TRANSACTIONS_RECORD_START',
  SUCCESS: 'CREATE_TRANSACTIONS_RECORD_SUCCESS',
  ERROR: 'CREATE_TRANSACTIONS_RECORD_ERROR',
};

export const FETCH_TRANSACTIONS_LIST = {
  START: 'FETCH_TRANSACTIONS_LIST_START',
  SUCCESS: 'FETCH_TRANSACTIONS_LIST_SUCCESS',
  ERROR: 'FETCH_TRANSACTIONS_LIST_ERROR',
};

export const EDIT_TRANSACTIONS_RECORD = {
  START: 'EDIT_TRANSACTIONS_RECORD_START',
  SUCCESS: 'EDIT_TRANSACTIONS_RECORD_SUCCESS',
  ERROR: 'EDIT_TRANSACTIONS_RECORD_ERROR',
};

export const FETCH_TRANSACTIONS_DETAIL = {
  START: 'FETCH_TRANSACTIONS_DETAIL_START',
  SUCCESS: 'FETCH_TRANSACTIONS_DETAIL_SUCCESS',
  ERROR: 'FETCH_TRANSACTIONS_DETAIL_ERROR',
};
export const EXPORT_TRANSACTIONS = {
  START: 'EXPORT_TRANSACTIONS_START',
  SUCCESS: 'EXPORT_TRANSACTIONS_SUCCESS',
  ERROR: 'EXPORT_TRANSACTIONS_ERROR',
};
export const TOAST = {
  RESET: 'RESET_TOAST',
};

export const IMPORT_TRANSACTIONS = {
  START: 'IMPORT_TRANSACTIONS_START',
  SUCCESS: 'IMPORT_TRANSACTIONS_SUCCESS',
  ERROR: 'IMPORT_TRANSACTIONS_ERROR',
}
