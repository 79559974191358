export const FORM_FIELDS_NAME = {
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  COMPANY: "company",
  SITE_NAME: "siteName",
  SITE_NUMBER: "siteNumber",
  COUNTRY: "country",
  CARD_NUMBER: "cardNumber",
  STATE: "state",
  CITY: "city",
  VEHICLE_IDENTIFIERS: {
    DRIVER_ID: "vehicleInfo.driverId",
    POS_DRIVER_ID: "vehicleInfo.posDriverId",
    VEHICLE_NUMBER: "vehicleInfo.vehicleNumber",
    VEHICLE_NUMBER_POS_DEVICE: "vehicleInfo.vehicleNumberPOSDevice",
    TRAILER_NUMBER: "vehicleInfo.trailerNumber",
    TRAILER_NUMBER_POS_DEVICE: "vehicleInfo.trailerNumberPOSDevice",
    DRIVER_CDL: "vehicleInfo.driverCdl",
    POS_DRIVER_CDL: "vehicleInfo.POSdriverCDL",
    BILL_TOTAL: "vehicleInfo.billTotal",
    STATE: "vehicleInfo.state",
  },
  FUELPURCHASE: {
    FUEL_CODE: "fuelPurchase.fuelCode",
    BILLERPRICE: "fuelPurchase.billerPrice",
    RETAILPRICE: "fuelPurchase.retailPrice",
    QUANTITY: "quantity",
    HST: "fuelPurchase.hst",
    GST: "fuelPurchase.gst",
    PST: "fuelPurchase.pst",
    QST: "fuelPurchase.qst",
    FET: "fuelPurchase.fet",
    PET: "fuelPurchase.pet",
    PCT: "fuelPurchase.pct",
    PFT: "fuelPurchase.pft",
    LOCAL_TAX: "fuelPurchase.localTax",
    AMOUNT: "fuelPurchase.amount",
    DISCOUNT: "fuelPurchase.discount",
  },
  NONHIGHWAYPURCHASE: {
    FUEL_CODE: "nonHighwayPurchase.fuelCode",
    BILLERPRICE: "nonHighwayPurchase.billerPrice",
    RETAILPRICE: "nonHighwayPurchase.retailPrice",
    QUANTITY: "nonHighwayPurchase.quantity",
    HST: "nonHighwayPurchase.hst",
    GST: "nonHighwayPurchase.gst",
    PST: "nonHighwayPurchase.pst",
    QST: "nonHighwayPurchase.qst",
    FET: "nonHighwayPurchase.fet",
    PET: "nonHighwayPurchase.pet",
    PCT: "nonHighwayPurchase.pct",
    PFT: "nonHighwayPurchase.pft",
    LOCAL_TAX: "nonHighwayPurchase.localTax",
    AMOUNT: "nonHighwayPurchase.amount",
    DISCOUNT: "nonHighwayPurchase.discount",
  },
  OTHER1PURCHASE: {
    FUEL_CODE: "other1Purchase.fuelCode",
    QUANTITY: "other1Purchase.quantity",
    BILLERPRICE: "other1Purchase.billerPrice",
    AMOUNT: "other1Purchase.amount",
    HST: "other1Purchase.hst",
    GST: "other1Purchase.gst",
    PST: "other1Purchase.pst",
    QST: "other1Purchase.qst",
    LOCAL_TAX: "other1Purchase.localTax",
  },
  OTHER2PURCHASE: {
    FUEL_CODE: "other2Purchase.fuelCode",
    QUANTITY: "other2Purchase.quantity",
    BILLERPRICE: "other2Purchase.billerPrice",
    AMOUNT: "other2Purchase.amount",
    HST: "other2Purchase.hst",
    GST: "other2Purchase.gst",
    PST: "other2Purchase.pst",
    QST: "other2Purchase.qst",
    LOCAL_TAX: "other2Purchase.localTax",
  },
  CASHPURCHASE: {
    FUEL_CODE: "cashPurchase.fuelCode",
    DASHCASH: "cashPurchase.dashCash",
  },
  OTHERPURCHASE: {
    OIL_QUANTITY: "otherPurchase.quantity",
    AMOUNT: "otherPurchase.amount",
    HST: "otherPurchase.hst",
    GST: "otherPurchase.gst",
    PST: "otherPurchase.pst",
    QST: "otherPurchase.qst",
  },
};

export const prepareFormData = (formFieldValueMap) => {
  const fuelPurchase = [];
  const other1Purchase = [];
  const other2Purchase = [];

  formFieldValueMap?.txn_attr?.forEach((txn, index) => {
    if (txn.dtl_prd_cd === "D1") {
      if (index === 0) {
        fuelPurchase.push({
          dtl_prd_cd: txn.dtl_prd_cd,
          BilledPriceperUnit: formFieldValueMap.txn_price_per_unit,
          RetailPriceperUnit: txn.retailPrice, 
          quantity: txn.dtl_qty,
          billedPrice: txn.dtl_net_price,
          amount: txn.dtl_amt,
          hst: txn.dtl_hst_amt,
          gst: txn.dtl_gst_amt,
          pst: txn.dtl_pst_amt,
          qst: txn.dtl_qst_amt,
          pft: txn.dtl_pft_rt,
          localTax: txn.dtl_cbt_rt,
        });
      } else if (index === 1) {
        other1Purchase.push({
          fuelCode: txn.dtl_prd_cd,
          quantity: txn.dtl_qty,
          billedPrice: txn.dtl_net_price,
          amount: txn.dtl_amt,
          hst: txn.dtl_hst_amt,
          gst: txn.dtl_gst_amt,
          pst: txn.dtl_pst_amt,
          qst: txn.dtl_qst_amt,
          localTax: txn.dtl_cbt_rt,
        });
      } else {
        other2Purchase.push({
          fuelCode: txn.dtl_prd_cd,
          quantity: txn.dtl_qty,
          billedPrice: txn.dtl_net_price,
          amount: txn.dtl_amt,
          hst: txn.dtl_hst_amt,
          gst: txn.dtl_gst_amt,
          pst: txn.dtl_pst_amt,
          qst: txn.dtl_qst_amt,
          localTax: txn.dtl_cbt_rt,
        });
      }
    } else if (txn.dtl_prd_cd === "DEF") {
      other1Purchase.push({
        fuelCode: txn.dtl_prd_cd,
        quantity: txn.dtl_qty,
        billedPrice: txn.dtl_net_price,
        amount: txn.dtl_amt,
        hst: txn.dtl_hst_amt,
        gst: txn.dtl_gst_amt,
        pst: txn.dtl_pst_amt,
        qst: txn.dtl_qst_amt,
        localTax: txn.dtl_cbt_rt,
      });
    }
  });

  // If "DEF", zero out fuelPurchase
  if (formFieldValueMap?.txn_attr?.some((txn) => txn.dtl_prd_cd === "DEF")) {
    return { fuelPurchase: [], other1Purchase, other2Purchase };
  }

  return { fuelPurchase, other1Purchase, other2Purchase };
};
