import { ProgressBar } from "primereact/progressbar";
import billedFuelInvoiceData from "./tableData.json";
import CreateInvoicesComponent from "../../../components/Invoices/CreateInvoices";
import { useEffect, useState } from "react";
import { APP_URLS } from "../../../constants/urlConstants";
import BilledFuelInvoice from "../../../components/Invoices/BilledFuelInvoices";
import {
  createInvoice,
  editInvoice,
  fetchInvoiceById,
  fetchInvoiceStatuses,
  initCreateNewInvoiceScreen,
} from "../../../Redux/Actions/invoice";
import { connect } from "react-redux";
import { setIsformDirty } from "../../../Redux/Actions/common/form";
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";

const CreateInvoice = (props) => {
  const {
    history,
    createInvoice,
    editInvoice,
    isEdit,
    isView,
    isLoading,
    isCreateInvoiceSuccess,
    isCreateInvoiceError,
    isInvoiceDetailSuccess,
    isInvoiceDetailError,
    isEditInvoiceSuccess,
    isEditInvoiceError,
    formFieldValueMap,
    invoiceStatuses,
    fetchInvoiceById,
    shouldBlockNavigation,
    fuelTransactionDtoList,
    fetchInvoiceStatuses,
  } = props;

  const { id } = props.match.params;
  const InvoiceId = id;

  useEffect(() => {
    if (isEdit || isView) {
      fetchInvoiceStatuses();
      fetchInvoiceById({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView, isEdit]);

  const navigateToInvoicesScreen = () => {
    history.push(APP_URLS.INVOICES.HOME);
  };

  const createInvoicesProps = {
    isLoading,
    createInvoice,
    editInvoice,
    isEdit,
    isView,
    isCreateInvoiceSuccess,
    isEditInvoiceSuccess,
    isInvoiceDetailSuccess,
    isEditInvoiceError,
    InvoiceId,
    invoiceStatuses,
    formFieldValueMap,
    shouldBlockNavigation,
    navigateToInvoicesScreen,
  };

  const billedFuelInvoiceProps = {
    ...billedFuelInvoiceData,
    fuelTransactionDtoList
  };

  const getToastProps = () => {
    if (isCreateInvoiceSuccess || isEditInvoiceSuccess) {
      const toastTitle = isEdit
        ? "Invoice Updated Successfully"
        : "Invoice Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (isCreateInvoiceError || isInvoiceDetailError || isEditInvoiceError) {
      let toastTitle = "Error while Creating Invoice";
      if (isEditInvoiceError) {
        toastTitle = "Error while updating Invoice";
      } else if (isInvoiceDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateInvoicesComponent {...createInvoicesProps} />
      <BilledFuelInvoice {...billedFuelInvoiceProps} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapStateInvoices", state);
  return {
    isLoading: selectFromInvoicesReducer(state, "isLoading"),
    isCreateInvoiceSuccess: selectFromInvoicesReducer(
      state,
      "isCreateInvoiceSuccess"
    ),
    isCreateInvoiceError: selectFromInvoicesReducer(
      state,
      "isCreateInvoiceError"
    ),
    isInvoiceDetailSuccess: selectFromInvoicesReducer(
      state,
      "isInvoiceDetailSuccess"
    ),
    isInvoiceDetailError: selectFromInvoicesReducer(
      state,
      "isInvoiceDetailError"
    ),
    isEditInvoiceSuccess: selectFromInvoicesReducer(
      state,
      "isEditInvoiceSuccess"
    ),
    isEditInvoiceError: selectFromInvoicesReducer(state, "isEditInvoiceError"),
    formFieldValueMap: selectFromInvoicesReducer(state, "formFieldValueMap"),
    fuelTransactionDtoList: selectFromInvoicesReducer(state, "fuelTransactionDtoList"),
    invoiceStatuses: selectFromInvoicesReducer(state, "invoiceStatuses"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewInvoiceScreen: () => dispatch(initCreateNewInvoiceScreen()),
    fetchInvoiceById: (payload) => dispatch(fetchInvoiceById(payload)),
    createInvoice: (payload) => dispatch(createInvoice(payload)),
    editInvoice: (payload) => dispatch(editInvoice(payload)),
    shouldBlockNavigation: (isFormDirty) =>
      dispatch(setIsformDirty(isFormDirty)),
    fetchInvoiceStatuses: () => dispatch(fetchInvoiceStatuses())
  };
};

const selectFromInvoicesReducer = (state, path) => {
  return selectInvoicesReducer(state)[path];
};

const selectInvoicesReducer = ({ invoiceReducer }) => {
  return invoiceReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
