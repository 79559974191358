import React, { useEffect } from "react";
import { connect } from "react-redux";
import CreateTransaction from "../../../components/Transactions/CreateTransaction";
import { APP_URLS } from "../../../constants/urlConstants";
import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import { ProgressBar } from "primereact/progressbar";
import {
  initializeTransactionScreen,
  createTransaction,
  editTransaction,
  fetchTransactionById,
} from "../../../Redux/Actions/transactions";
import { setIsformDirty } from "../../../Redux/Actions/common/form";
const CreateTransactions = (props) => {
  const {
    isEdit,
    isView,
    isLoading,
    history,
    isCreateTransactionSuccess,
    isCreateTransactionError,
    isTransactionDetailError,
    isTransactionDetailSuccess,
    isEditTransactionSuccess,
    isEditTransactionError,
    isTransactionExportSuccess,
    isTransactionExportError,
    createTransaction,
    editTransaction,
    shouldBlockNavigation,
    formFieldValueMap,
    fetchTransactionById
  } = props;
  
  const { id } = props.match.params;
  useEffect(() => {
    if (isEdit || isView) {
      fetchTransactionById({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isView]);

  const { transactionId } = props.match.params;
  const navigateToTransactionSummaryScreen = () => {
    history.push(APP_URLS.TRANSACTIONS.HOME);
  }
  const createTransactionProps = {
    createTransaction,
  editTransaction,
    navigateToTransactionSummaryScreen,
    isLoading,
    isEdit,
    isView,
    transactionId,
    isCreateTransactionSuccess,
    isCreateTransactionError,
    isTransactionDetailError,
    isTransactionDetailSuccess,
    isEditTransactionSuccess,
    isEditTransactionError,
    isTransactionExportSuccess,
    isTransactionExportError,
    shouldBlockNavigation,
    formFieldValueMap
  };

  
  const getToastProps = () => {
    if (isCreateTransactionSuccess || isEditTransactionSuccess) {
      const toastTitle = isEdit
        ? "Transaction Updated Successfully"
        : "Transaction Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isCreateTransactionError ||
      isEditTransactionError
    ) {
      let toastTitle = "Error while Creating Transaction";
      if (isTransactionDetailError) {
        toastTitle = "Error while updating Transaction";
      } else if (isTransactionDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };
  return (
    <div>
        {isLoading && renderProgressBar()}
        <EzToast {...getToastProps()} />
      <CreateTransaction {...createTransactionProps} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromTransactionReducer(state, "isLoading"),
    isCreateTransactionError: selectFromTransactionReducer(
      state,
      "isCreateTransactionError"
    ),
    isCreateTransactionSuccess: selectFromTransactionReducer(
      state,
      "isCreateTransactionSuccess"
    ),
    isTransactionDetailSuccess: selectFromTransactionReducer(
      state,
      "isTransactionDetailSuccess"
    ),
    isTransactionDetailError: selectFromTransactionReducer(
      state,
      "isTransactionDetailError"
    ),
    isEditTransactionSuccess: selectFromTransactionReducer(
      state,
      "isEditTransactionSuccess"
    ),
    isEditTransactionError: selectFromTransactionReducer(
      state,
      "isEditTransactionError"
    ),
    formFieldValueMap: selectFromTransactionReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeTransactionScreen: () =>
      dispatch(initializeTransactionScreen()),
    fetchTransactionById: (payload) =>
      dispatch(fetchTransactionById(payload)),
    createTransaction: (payload) => dispatch(createTransaction(payload)),
    editTransaction: (payload) => dispatch(editTransaction(payload)),
    shouldBlockNavigation: (isFormDirty) =>
      dispatch(setIsformDirty(isFormDirty)),
  };
};

const selectFromTransactionReducer = (state, path) => {
  return selectTransactionReducer(state)[path];
};

const selectTransactionReducer = ({ transactionReducer }) => {
  return transactionReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTransactions);
