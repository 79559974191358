import React from "react";
import { InputText } from "primereact/inputtext";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

const EzControlledInputWithFloatingLabel = (props) => {
  const {
    control,
    name,
    disabled,
    type,
    rules,
    labelClassName,
    onChange,
    label,
    errorMsg,
    isError,
    wrapperClass,
    placeholder,
    inputStyle,
  } = props;

  const getLabelClassName = () => {
    return classNames({
      "p-error": isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      <span className={wrapperClass}>
        <label htmlFor={name} className={getLabelClassName()}>
          {label}
          {rules?.required ? <span style={{ color: "red" }}>*</span> : null}
        </label>

        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
              <InputText
                type={type}
                id={field.name}
                name={field.name}
                style={inputStyle ? JSON.parse(inputStyle) : { width: "100%" }}
                placeholder={placeholder}
              onChange={onChange}
                disabled={disabled}
                value={field.value}
              {...field}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
          )}
        />
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledInputWithFloatingLabel.defaultProps = {
  labelClassName: "",
  disabled: false,
  type: "text",
  wrapperClass: "",
  inputStyle: "",
};

export default EzControlledInputWithFloatingLabel;
