import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

const FileUploadModal = ({
  isOpen,
  onClose,
  onUpload,
  title = "Upload File",
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      return;
    }
    onUpload(selectedFile);
    handleClose();
  };

  const handleClose = () => {
    setSelectedFile(null);
    onClose();
  };

  return (
    <Dialog
      header={title}
      visible={isOpen}
      onHide={handleClose}
      style={{ width: "25vw" }}
    >
      <FileUpload
        mode="basic"
        auto={false}
        chooseLabel="Select File"
        onSelect={handleFileSelect}
        className="mt-2"
      />

      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button
          label="Cancel"
          className="p-btn-danger p-button-outlined mr-2"
          onClick={handleClose}
        />
        <Button
          label="Upload"
          className="p-button-raised"
          onClick={handleUpload}
          disabled={!selectedFile}
        />
      </div>
    </Dialog>
  );
};

export default FileUploadModal;
