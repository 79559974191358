import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";
import EzTable from "../../common/EzTable";
import { EzToast, TOAST_SEVERITY } from "../../common/EzToast";
import EzNotFound from "../../common/EzNotFound";
import { downloadCSV } from "../../utils/download";
import FileUploadModal from "../../common/FileUploadModal";

const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
let SelectedRecords = [];

const ManageCardComponent = (props) => {
  const {
    tableData,
    paginationInfo,
    screenPermissions,
    createManageards,
    editManageCard,
    readManageCard,
    isPageLevelError,
    fetchManageCards,
    exportManageCard,
    isLoading,
    importManageCard,
    downloadSampleCardTemplate,
    isManageCardExportError,
    isManageCardExportSuccess,
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleExportRecord = () => {
    let data = SelectedRecords;
    let payload = data.map((item) => item.id);
    exportManageCard(payload);
  };

  const handleImportCard = () => {
    setModalOpen(true);
  };

  const handleUpload = (file) => {
    importManageCard(file);
  };

  const handleDownloadSampleCardTemplate = () => {
    downloadSampleCardTemplate()
  }

  const getToastProps = () => {
    if (isManageCardExportSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: "Cards Exported Successfully",
        shouldShowToast: true,
      };
    }

    if (isManageCardExportError) {
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle: "Error while Exporting Card",
        shouldShowToast: true,
      };
    }
  };
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div className="ml-3">
              <h4>Manage Cards</h4>
            </div>
            <Button
              label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
              icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
              className={`p-button-text p-button-secondary`}
              onClick={() => {
                toggleFilterState(!areFiltersVisible);
              }}
            />
          </div>
          <div>
            <Button
              label={"Import Card"}
              className={`p-button-raised`}
              style={{ borderRadius: "10px" }}
              onClick={handleImportCard}
            />
            <Button
              label={"Exports Selected"}
              className={`p-button-raised ml-2`}
              style={{ borderRadius: "10px" }}
              onClick={handleExportRecord}
            />
            <Button
              label={"Download Sample Card Template"}
              className={`p-button-raised ml-2`}
              style={{ borderRadius: "10px" }}
              onClick={handleDownloadSampleCardTemplate}
            />
          </div>
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderManageCardRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderManageCardRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    company_name: { filterKey: "company_name", value: null },
    company_number: { filterKey: "company_number", value: null },
    plastic_type: { filterKey: "plastic_typee", value: null },
    trailer_number: { filterKey: "trailer_number", value: null },
    card_status: { filterKey: "card_status", value: null },
    driver_name: { filterKey: "driver_name", value: null },
    // add other fields
  };

  const handleCallback = (childData) => {
    SelectedRecords = childData;
  };
  let isCallbackEnable = true;
  const tableProps = {
    areFiltersVisible,
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Manage cards found.",
    filters,
    sortField: null,
    loading: isLoading,
    showMoreActions: false,
    onEditRecord: editManageCard,
    onReadRecord: readManageCard,
    loadLazyData: fetchManageCards,
    parentCallback: handleCallback,
    isCallbackEnable,
  };

  return (
    <>
      <div className="paddingbox">
        <EzToast {...getToastProps()} />
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderManageCardRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No Manage cards records to show"}
              />
            )}
          </div>
        </div>
      </div>
      <FileUploadModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onUpload={handleUpload}
        title="Import Manage Card"
      />
    </>
  );
};
export default ManageCardComponent;
