import { TOAST_SEVERITY } from "../common/EzToast";

export const truncateStringWithEllipses = (limitedLength, value = '') =>
value.length > limitedLength ? value.slice(0, limitedLength) + '...' : value; 

export const getToastProps = (isSuccess, isError, successMessage, errorMessage) => {
  if (isSuccess) {
    return {
      severity: TOAST_SEVERITY.SUCCESS,
      toastTitle: successMessage,
      shouldShowToast: true,
    };
  }
  if (isError) {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: errorMessage,
      shouldShowToast: true,
    };
  }
  return {};
};
