export const getBrandFromURL = (pathname) => {
  // Check if the brand is included in the URL path
  if (pathname.includes("combpetro")) {
    return "combpetro";
  }
  if (pathname.includes("combinepetro")) {
    return "combinepetro";
  }
  if (pathname.includes("cpfleets")) {
    return "cpfleets";
  }
  return "freshfuels";
};
