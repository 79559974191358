

export const INIT_COMPANY = 'INIT_COMPANY';
export const FETCH_COMPANY_LIST_START = 'FETCH_COMPANY_LIST_START';
export const FETCH_COMPANY_LIST_SUCCESS = 'FETCH_COMPANY_LIST_SUCCESS';
export const FETCH_COMPANY_LIST_ERROR = 'FETCH_COMPANY_LIST_ERROR';

export const FETCH_COMPANY_DETAIL = {
    START: 'FETCH_COMPANY_DETAIL_START',
    SUCCESS: 'FETCH_COMPANY_DETAIL_SUCCESS',
    ERROR: 'FETCH_COMPANY_DETAIL_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };

  export const FETCH_CUSTOMER_LIST_START = "FETCH_CUSTOMER_LIST_START";
  export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS";
  export const FETCH_CUSTOMER_LIST_ERROR = "FETCH_CUSTOMER_LIST_ERROR";