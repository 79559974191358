import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import EzControlledInput from "../../../common/EzForm/EzControlledInput";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import ReCAPTCHA from "react-google-recaptcha";
import "./style.css";
import {
  countries,
  cityOptions,
  businessOptions,
  subBusinessOptions,
} from "./data.json";

const CreateCompanyComponent = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [plan, setPlan] = useState("Individual");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const onSubmit = (data) => {
    if (!recaptchaValue) {
      alert("Please verify that you're not a robot!");
      return;
    }
    console.log(data);
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative mb-3">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div>
              <h4>Create Company</h4>
            </div>
          </div>
          <Button
            tooltip={"Back to Company List"}
            tooltipOptions={{ position: "left" }}
            label={"Back"}
            className={`p-button`}
            style={{ borderRadius: "10px" }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="form-container">
      {renderPageHeaderSection()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <EzControlledInput
              name="firstName"
              control={control}
              label="First Name"
              rules={{ required: "First Name is required" }}
              errorMsg={errors.firstName && errors.firstName.message}
              isError={!!errors.firstName}
            />
          </Col>
          <Col md={6}>
            <EzControlledInput
              name="lastName"
              control={control}
              label="Last Name"
              rules={{ required: "Last Name is required" }}
              errorMsg={errors.lastName && errors.lastName.message}
              isError={!!errors.lastName}
            />
          </Col>
        </Row>

        <EzControlledInput
          name="email"
          control={control}
          label="Email"
          type="email"
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
              message: "Invalid email address",
            },
          }}
          errorMsg={errors.email && errors.email.message}
          isError={!!errors.email}
        />

        <Row>
          <Col md={6}>
            <EzControlledInput
              name="password"
              control={control}
              label="Password"
              type="password"
              rules={{ required: "Password is required" }}
              errorMsg={errors.password && errors.password.message}
              isError={!!errors.password}
            />
          </Col>
          <Col md={6}>
            <EzControlledInput
              name="confirmPassword"
              control={control}
              label="Confirm Password"
              type="password"
              rules={{
                required: "Confirm Password is required",
                validate: (value) =>
                  value === getValues("password") || "Passwords do not match",
              }}
              errorMsg={
                errors.confirmPassword && errors.confirmPassword.message
              }
              isError={!!errors.confirmPassword}
            />
          </Col>
        </Row>

        <EzControlledInput
          name="contactNumber"
          control={control}
          label="Contact Number"
          rules={{
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Invalid contact number",
            },
          }}
          errorMsg={errors.contactNumber && errors.contactNumber.message}
          isError={!!errors.contactNumber}
        />

        <EzControlledInput
          name="streetAddress1"
          control={control}
          label="Street Address 1"
        />

        <EzControlledInput
          name="streetAddress2"
          control={control}
          label="Street Address 2"
        />

        <Row>
          <Col md={6}>
            <EzControlledDropDowntWithFloatingLabel
              name="country"
              control={control}
              label="Select Country"
              options={countries}
              rules={{ required: "Country is required" }}
              errorMsg={errors.country && errors.country.message}
              isError={!!errors.country}
            />
          </Col>
          <Col md={6}>
            <EzControlledDropDowntWithFloatingLabel
              name="state"
              control={control}
              label="Select State"
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <EzControlledDropDowntWithFloatingLabel
              name="city"
              control={control}
              label="Select City"
              options={cityOptions}
            />
          </Col>
          <Col md={6}>
            <EzControlledInput
              name="zipCode"
              control={control}
              label="Zip/Postal Code"
              rules={{
                pattern: {
                  value: /^[0-9]{5,6}$/,
                  message: "Invalid zip/postal code",
                },
              }}
              errorMsg={errors.zipCode && errors.zipCode.message}
              isError={!!errors.zipCode}
            />
          </Col>
        </Row>

        <div>Plan*</div>
        <div className="radio-group-container d-flex justify-content-center gap-5 my-3 w-30">
          <div className="form-check d-flex align-items-center gap-2">
            <input
              type="radio"
              className="form-check-input"
              id="individual"
              value="Individual"
              checked={plan === "Individual"}
              onChange={() => setPlan("Individual")}
            />
            <label className="form-check-label" htmlFor="individual">
              Individual
            </label>
          </div>

          <div className="form-check d-flex align-items-center gap-2">
            <input
              type="radio"
              className="form-check-input"
              id="smallBusiness"
              value="Small Business"
              checked={plan === "Small Business"}
              onChange={() => setPlan("Small Business")}
            />
            <label className="form-check-label" htmlFor="smallBusiness">
              Small Business
            </label>
          </div>
        </div>

        <EzControlledInput
          name="companyName"
          control={control}
          label="Company Name"
          rules={{ required: "Company Name is required" }}
          errorMsg={errors.companyName && errors.companyName.message}
          isError={!!errors.companyName}
        />

        <EzControlledDropDowntWithFloatingLabel
          name="business"
          control={control}
          label="Business"
          options={businessOptions}
          rules={{ required: "Business type is required" }}
          errorMsg={errors.business && errors.business.message}
          isError={!!errors.business}
        />

        <EzControlledDropDowntWithFloatingLabel
          name="subBusiness"
          control={control}
          label="Sub Business"
          options={subBusinessOptions}
        />

        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey="YOUR_RECAPTCHA_SITE_KEY"
            onChange={onRecaptchaChange}
          />
        </div>

        <div className="form-group mt-3">
          <Button type="submit" className="w-100" label="Create" />
        </div>
      </form>
    </div>
  );
};

export default CreateCompanyComponent;
