import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";

const UploadModal = ({ visible, onHide, onUpload }) => {
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [file, setFile] = useState(null);

  const fileTypes = [
    { label: "ESSO", value: "ESSO" },
    { label: "Suncore", value: "Suncore" },
    { label: "Comdata", value: "Comdata" },
    { label: "Pipeline", value: "Pipeline" },
    { label: "TAPetro", value: "TAPetro" },
  ];

  const handleFileSelect = (event) => {
    if (event.files && event.files.length > 0) {
      setFile(event.files[0]);
    }
  };

  const resetState = () => {
    setFile(null);
    setSelectedFileType(null);
  };

  const handleUpload = () => {
    if (file && selectedFileType) {
      onUpload(file, selectedFileType, resetState);
      onHide();
    }
  };

  const handleClose = () => {
    resetState();
    onHide();
  };

  return (
    <Dialog
      header="Import Transaction"
      visible={visible}
      style={{ width: "35vw" }}
      modal
      onHide={handleClose}
      closable={true}
      className="upload-modal"
      ariaCloseButtonLabel="Close"
    >
      <div className="p-fluid">
        <p className="mt-2">
          This will open a new tab with the file to download.
        </p>
        <p>Warning: This process may take several minutes.</p>
        <Dropdown
          value={selectedFileType}
          options={fileTypes}
          onChange={(e) => setSelectedFileType(e.value)}
          placeholder="Select File Type"
          className="mb-3"
        />
        <FileUpload
          mode="basic"
          chooseLabel="Select File"
          customUpload
          auto
          onSelect={handleFileSelect}
          accept=".xls,.xlsx"
        />
        {file && <p className="mt-2">Selected file: {file.name}</p>}
      </div>

      <div className="d-flex justify-content-end mt-4">
        <Button
          label="Close"
          onClick={handleClose}
          className="p-btn-danger p-button-outlined mr-2"
        />
        <Button
          label="Upload"
          onClick={handleUpload}
          className="p-button-raised"
          disabled={!file || !selectedFileType}
        />
      </div>
    </Dialog>
  );
};

UploadModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default UploadModal;
