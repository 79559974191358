import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";
import EzTable from "../../common/EzTable";
import { EzToast, TOAST_SEVERITY } from "../../common/EzToast";
import EzNotFound from "../../common/EzNotFound";
import EzTableNested from "../../common/EzTable/NestedTable";
import UploadModal from "../../common/UploadModal";
const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const Transaction = (props) => {
  const {
    fetchTransactions,
    tableData,
    importTransaction,
    history,
    initTransactionScreen,
    showPageLevelLoader,
    transactionsListView,
    isTransactionExportError,
    isTransactionExportSuccess,
    isPageLevelError,
    paginationInfo,
    isLoading,
    exportTransaction,
    screenPermissions,
    editTransaction,
    viewTransaction
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const handleFileUpload = (file, fileType, resetState) => {
    const payload = {
      file: file,
      type: fileType
    }
    importTransaction(payload)
    resetState();
  };

  const importTransactionModel = () => {
    setUploadModalVisible(true);
  };

  const renderPageHeaderSection = () => {
    return (
       <div className="make-relative">
       <div className="" style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
         <div>
          <div className="ml-3">
          <h4>Transactions</h4>
          </div>
           {/* <Button
             label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
             icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
             className={`p-button-text p-button-secondary`}
             onClick={() => {
               toggleFilterState(!areFiltersVisible);
             }}
           /> */}
         </div>
         <Button
           label={"Import Transaction"}
           className={` p-button-raised`}
           style={{ borderRadius: "10px" , }}
           onClick={importTransactionModel}
         />
       </div>
     </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderComplianceRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderComplianceRecords() && !shouldRenderFullPageError();
  const filters = {
    global: { value: null },
    company_name: { filterKey: "company_name", value: null },
    company_number: { filterKey: "company_number", value: null },
    plastic_type: { filterKey: "plastic_typee", value: null },
    trailer_number: { filterKey: "trailer_number", value: null },
    card_status: { filterKey: "card_status", value: null },
    driver_name: { filterKey: "driver_name", value: null },
  };

    
  
  const tableProps = {
    areFiltersVisible,
    columns: tableData?.columns,
    subcolumns: tableData?.subcolumns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Transaction found.",
    filters,
    loadLazyData: fetchTransactions,
    loading: false,
    showMoreActions: false,
    sortField: null,
    isLoading,
    onEditRecord:editTransaction,
    onReadRecord:viewTransaction,
    SUBKEY:"sub_transactions"
  };
  return (
    <>
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderComplianceRecords() && (
              <Row>
                <Col md={12}>
                  <EzTableNested {...tableProps} /> 
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No transaction records to show"}
              />
            )}
          </div>
          <UploadModal
            visible={uploadModalVisible}
            onHide={() => setUploadModalVisible(false)}
            onUpload={handleFileUpload}
          />
        </div>
      </div>
    </>
  );
};
export default Transaction;
