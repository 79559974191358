import { useEffect, useRef, useState } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import invoicesData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import Invoices from "../../components/Invoices";
import { connect } from "react-redux";
import {
  initializeInvoiceScreen,
  fetchInvoice,
  downloadInvoice,
  sendInvoiceEmail,
} from "../../Redux/Actions/invoice";
import DownloadModal from "../../common/DownloadModal";
import { EzToast, TOAST_SEVERITY } from "../../common/EzToast";
import { Toast } from "primereact/toast";
import PopupDialog from "../../common/PopupDialog";
import { ProgressBar } from "primereact/progressbar";
import { getToastProps } from "../../utils/common";

const InvoicesScreen = (props) => {
  const {
    fetchInvoice,
    downloadInvoice,
    history,
    initializeInvoiceScreen,
    showPageLevelLoader,
    invoiceListView,
    paginationInfo,
    isLoading,
    screenPermissions,
    isDownloadSuccess,
    isDownloadError,
    sendInvoiceEmail,
    isEmailSuccess,
    isEmailError,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileType, setFileType] = useState("PDF");
  const [selectedRow, setSelectedRow] = useState(null);
  const toast = useRef(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const closePopup = () => setIsPopupVisible(false);
  const closeModal = () => setIsModalVisible(false);

  useEffect(() => {
    initializeInvoiceScreen();
    fetchInvoice();
  }, []);

  const createInvoice = () => history.push(APP_URLS.INVOICES.ADD_INVOICES);

  const editInvoice = ({ invoice_number }) => {
    history.push(
      generatePath(APP_URLS.INVOICES.EDIT_INVOICES, { id: invoice_number })
    );
  };

  const readInvoice = ({ invoice_number }) => {
    history.push(
      generatePath(APP_URLS.INVOICES.VIEW_INVOICES, { id: invoice_number })
    );
  };

  const emailInvoice = ({ id }) => {
    sendInvoiceEmail(id);
  };

  const handleExport = () => {
    if (selectedRow && selectedRow.invoice_number) {
      const payload = {
        invoiceNumber: selectedRow.invoice_number,
        fileType: fileType,
      };
      downloadInvoice(payload);
    } else {
      if (toast.current) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an invoice to export.",
          life: 3000,
        });
      }
    }
    closeModal();
  };

  const downloadInvoiceModelOpen = (rowData) => {
    setSelectedRow(rowData);
    setIsModalVisible(true);
  };

  const loadLazyData = (props) => {
    fetchInvoice(props);
  };

  const invoicesProps = {
    createInvoice,
    editInvoice,
    emailInvoice,
    readInvoice,
    downloadInvoiceModelOpen,
    screenPermissions,
    fetchInvoices: loadLazyData,
    ...invoicesData,
    tableData: {
      ...invoicesData.tableData,
      rows: invoiceListView,
    },
    paginationInfo: {
      ...invoicesData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.total_elements,
    },
    isLoading,
  };

  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");

  return (
    <div>
      {isLoading && (
        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
      )}
      <EzToast
        {...getToastProps(
          isDownloadSuccess,
          isDownloadError,
          `Invoice Download ${fileType} Successfully`,
          `Error while Downloading ${fileType} Invoice`
        )}
      />
      <EzToast
        {...getToastProps(
          isEmailSuccess,
          isEmailError,
          "Invoice emailed successfully!",
          "Error while sending invoice email."
        )}
      />
      <Toast ref={toast} />
      <Invoices {...invoicesProps} />
      <DownloadModal
        visible={isModalVisible}
        onExport={handleExport}
        onHide={closeModal}
        fileType={fileType}
        setFileType={setFileType}
      />
      <PopupDialog
        visible={isPopupVisible}
        onHide={closePopup}
        header="Invoice Notification"
        message="The invoice is in sent state, it can not be edited."
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeInvoiceScreen: () => dispatch(initializeInvoiceScreen()),
    fetchInvoice: (payload) => dispatch(fetchInvoice(payload)),
    downloadInvoice: (payload) => dispatch(downloadInvoice(payload)),
    sendInvoiceEmail: (payload) => dispatch(sendInvoiceEmail(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isLoading: selectFromInvoicesReducer(state, "isLoading"),
    invoiceListView: selectInvoiceList(state.invoiceReducer),
    showPageLevelLoader: selectIsLoadingPage(state.invoiceReducer),
    paginationInfo: selectPaginationInfo(state.invoiceReducer),
    isPageLevelError: selectIsPageLevelError(state.invoiceReducer),
    isFetchingGridData: selectIsLoading(state.invoiceReducer),
    isDownloadSuccess: selectFromInvoicesReducer(state, "isDownloadSuccess"),
    isDownloadError: selectFromInvoicesReducer(state, "isDownloadError"),
    isEmailSuccess: selectFromInvoicesReducer(state, "isEmailSuccess"),
    isEmailError: selectFromInvoicesReducer(state, "isEmailError"),
  };
};

const selectInvoiceList = (invoiceReducer) => invoiceReducer.invoiceListView;
const selectIsLoadingPage = (invoiceReducer) => invoiceReducer.isLoadingPage;
const selectPaginationInfo = (invoiceReducer) => invoiceReducer.paginationInfo;
const selectIsPageLevelError = (invoiceReducer) =>
  invoiceReducer.isPageLevelError;
const selectIsLoading = (invoiceReducer) => invoiceReducer.isLoading;

const selectFromInvoicesReducer = (state, path) => {
  return selectInvoicesReducer(state)[path];
};

const selectInvoicesReducer = ({ invoiceReducer }) => {
  return invoiceReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesScreen);
