import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FORM_FIELDS_NAME, prepareFormData } from "./constant";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Col, Container, Row } from "react-bootstrap";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import { Divider } from "primereact/divider";
import "./styles.css";
import { loadingsShow } from "../../../Services/PostAPI";
import EzControlledCalendarWithFloatingLabel from "../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import EzControlledInput from "../../../common/EzForm/EzControlledInput";
import Province from "../../../containers/common/Province";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { RenderPageHeaderSection } from "../../../common/PageHeaderFF";
import { goBack } from "../../../utils/gotoback";
import data from "./data.json";

const RecordTypeOption = [
  { label: "Positive", value: "yes" },
  { label: "Negative", value: "no" },
];
const PCOption = [
  { label: "PC1", value: "1" },
  { label: "PC2", value: "2" },
];
const CreateTransaction = (props) => {
  const {
    isEdit,
    isView,
    isLoading,
    navigateToTransactionSummaryScreen,
    createTransaction,
    editTransaction,
    formFieldValueMap,
    isTransactionDetailSuccess,
    isCreateTransactionSuccess,
    isEditTransactionSuccess,
    isEditTransactionError,
    shouldBlockNavigation,
  } = props;

  useEffect(() => {
    setTimeout(() => loadingsShow("none"), 1000);
  }, []);

  const { 
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      const { fuelPurchase, other1Purchase, other2Purchase } = prepareFormData(formFieldValueMap);
  
      // Push fuelPurchase and other arrays into formFieldValueMap
      return {
        formFieldValueMap: {
          ...formFieldValueMap, // Include the base fields
          fuelPurchase: fuelPurchase || [], // Nested directly inside formFieldValueMap
          other1Purchase: other1Purchase || [],
          other2Purchase: other2Purchase || [],
        },
      };
    }, [formFieldValueMap]), // Add formFieldValueMap as a dependency for memoization
  });

  useEffect(() => {
    const { fuelPurchase, other1Purchase, other2Purchase } = prepareFormData(formFieldValueMap);
  
    reset({
      formFieldValueMap: {
        ...formFieldValueMap,
        fuelPurchase: fuelPurchase || [],
        other1Purchase: other1Purchase || [],
        other2Purchase: other2Purchase || [],
      },
    });
  }, [formFieldValueMap, reset]);
  

  useEffect(() => shouldBlockNavigation(isDirty), [isDirty]);
  useEffect(() => {
    if (isCreateTransactionSuccess || isEditTransactionSuccess) {
      reset();
      navigateToTransactionSummaryScreen();
    }
  }, [isCreateTransactionSuccess]);

  useEffect(() => {
    if (isTransactionDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isTransactionDetailSuccess]);

  // const { fuelPurchase, other1Purchase, other2Purchase } = prepareFormData(
  //   formFieldValueMap
  // );



  const onSubmit = (data) => {
    const {
      cash_refresh_days,
      non_highway_fuel_weekly_refresh_day,
      weekly_vehicle_quantity_refresh_day,
      ...rest
    } = data;

    const stringifiedData = {
      ...rest,
      cash_refresh_days: JSON.stringify(cash_refresh_days),
      non_highway_fuel_weekly_refresh_day: JSON.stringify(
        non_highway_fuel_weekly_refresh_day
      ),
      weekly_vehicle_quantity_refresh_day: JSON.stringify(
        weekly_vehicle_quantity_refresh_day
      ),
    };
    isEdit
      ? editTransaction({ id: data.id, data: stringifiedData })
      : createTransaction(stringifiedData);
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <Button
          tooltip={"Back to Transaction summary"}
          tooltipOptions={{ position: "left" }}
          label={"Back to Transactions"}
          // icon={"pi pi-arrow-left"}
          className={`p-button `}
          style={{ borderRadius: "10px" }}
          onClick={navigateToTransactionSummaryScreen}
        />
      </div>
    );
  };

  const renderRecordType = (name, label) => {
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={RecordTypeOption}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={label}
        labelClassName={"ml-2"}
      />
    );
  };

  const renderControlledInput = (
    name,
    label,
    type = "text",
    rules = getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE
  ) => {
    return (
      <EzControlledInputWithFloatingLabel
        type={type}
        name={name}
        control={control}
        label={label}
        rules={rules}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        // disabled={isView || !isEdit}
      />
    );
  };

  const renderOther1PurchaseInputs = () => {
    return (
      <Row>
        {data.other1Purchase.map((field) => (
          <Col key={field.name} md={3} className="mb-3">
            {renderControlledInput(
              field.name, 
              field.label, 
              field.type, 
              field.rule 
            )}
          </Col>
        ))}
      </Row>
    );
  };

  const renderOther2PurchaseInputs = () => {
    return (
      <Row>
        {data.other2Purchase.map((field) => (
          <Col key={field.name} md={3} className="mb-3">
            {renderControlledInput(
              field.name, 
              field.label, 
              field.type, 
              field.rule
            )}
          </Col>
        ))}
      </Row>
    );
  };

  const renderOtherPurchaseInputs = () => {
    return (
      <Row>
        {data.otherPurchase.map((field) => (
          <Col key={field.name} md={3} className="mb-3">
            {renderControlledInput(
              field.name, 
              field.label, 
              field.type, 
              field.rule
            )}
          </Col>
        ))}
      </Row>
    );
  };

  const renderFuelPurchaseInputs = () => {
    const fuelPurchaseValues = watch("formFieldValueMap.fuelPurchase");
    return (
      <Row>
        {data.fuelPurchase.map((field) => (
          <Col key={field.name} md={3} className="mb-3">
            {renderControlledInput(
              field.name, 
              field.label, 
              field.type, 
              field.rule 
            )}
          </Col>
        ))}
      </Row>
    );
  };


  const renderTransactionDate = () => {
    const name = "txn_date";
    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={"Transaction Date"}
        // disabled={isView || !isEdit}
      />
    );
  };

  const renderTransactionTime = () => {
    const name = "txn_date";
    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={"Transaction Time"}
        timeOnly={true}
        showSeconds={true}
        // disabled={isView || !isEdit}
      />
    );
  };

  const renderProvince = () => {
    const name = "txn_attr[0].dtl_stn_prv";

    return (
      <Province
        name={name}
        control={control}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Station Province"
      />
    );
  };
  const renderProductCode = () => {
    const name = "txn_attr[0].dtl_prd_cd";
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={PCOption}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Product Code"
        labelClassName={"ml-2"}
      />
    );
  };

  const renderSalesType = () => {
    const name = "txn_attr[0].dtl_sls_type";
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={PCOption}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Sales type"
        labelClassName={"ml-2"}
      />
    );
  };

  return (
    <div className="paddingbox">
      <div
        className={classNames("containerboxform", { "p-disabled": isLoading })}
      >
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <Container fluid className="card-box">
              {renderDividerRowWithTime("View Transaction")}
              <Row>
                {/* <Col md="3">
                  {renderRecordType("trailer_record_type", "Record Type")}
                </Col> */}
                <Col md="3">
                  {renderControlledInput("card_number", "Card Number")}
                </Col>
                <Col md="3">{renderTransactionDate()}</Col>
                <Col md="3">{renderTransactionTime()}</Col>
                <Col md="3">
                  {renderControlledInput(
                    "txn_attr[0].dtl_stn_addr",
                    "Station Address"
                  )}
                </Col>
                <Col md="3">{isEdit ? renderProvince() : renderControlledInput("txn_attr[0].dtl_stn_prv", "Station Province")}</Col>
                <Col md="3">{isEdit ? renderProductCode() : renderControlledInput("txn_attr[0].dtl_prd_cd", "Product Code")} </Col>
                <Col md="3">
                  {renderControlledInput(
                    "txn_attr[0].dtl_prd_desc",
                    "Product Description"
                  )}
                </Col>

                <Col md="3">{renderControlledInput("txn_attr[0].dtl_qty", "QTY")}</Col>
                <Col md="3">
                  {renderControlledInput("txn_attr[0].dtl_net_price", "Net Price")}
                </Col>
                {/* <Col md="3">
                  {renderControlledInput(
                    "txn_attr.fed_exs_tax_rate",
                    "Fed Exs Tax Rate"
                  )}
                </Col> */}
                <Col md="3">
                  {renderControlledInput(
                    "txn_attr[0].provincial_fuel_tax_rate",
                    "Provincal Fuel Tax Rate"
                  )}
                </Col>
                <Col md="3">
                  {renderControlledInput("txn_attr[0].dtl_gst_amt", "GST Amt")}
                </Col>
                <Col md="3">
                  {renderControlledInput("txn_attr[0].dtl_pst_amt", "PST Amt")}
                </Col>
                <Col md="3">{renderControlledInput("txn_amount", "Amt")}</Col>
                <Col md="3">
                  {renderControlledInput("txn_attr[0].dtl_misc", "Misc")}
                </Col>
                <Col md="3">
                  {renderControlledInput("txn_attr[0].dtl_odometer", "Odometer")}
                </Col>
                <Col md="3">{isEdit ? renderSalesType() : renderControlledInput("txn_attr[0].dtl_sls_type", "Sales type")} </Col>
                <Col md="3">
                  {renderControlledInput(
                    "txn_attr[0].dtl_tran_no",
                    "Transaction Number"
                  )}
                </Col>

                <Col md="3">
                  {renderControlledInput("txn_attr[0].dtl_brkn_name", "Broker Name")}
                </Col>
                <Col md="3">
                  {renderControlledInput(
                    "txn_attr[0].dtl_stn_no",
                    "Station Number"
                  )}
                </Col>
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Fuel Purchase")}
              {renderFuelPurchaseInputs()}
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Other 1 Purchase")}
              {renderOther1PurchaseInputs()}
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Other 2 Purchase")}
              {renderOther2PurchaseInputs()}
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Other Purchase")}
              {renderOtherPurchaseInputs()}
            </Container>
            {isEdit && (
              <div
                className={"make-flex mt-3 mr-2"}
                style={{ flexDirection: "row-reverse", background: "white" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                    <Button
                      label="Cancel"
                      type="button"
                      className="p-btn-danger p-button-outlined mt-2"
                      onClick={goBack}
                    />
                  </div>
                  <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label="Save"
                      className="mt-2 p-button-raised"
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

const renderDividerRowWithTime = (label, align = "top", type = "solid") => {
  return (
    <Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          {" "}
          <b className="ml-2">{label || ""}</b>
        </div>
        <div>
          {" "}
          <b className="mr-2">
            <span>Last Updated at</span> {new Date().toLocaleString()}{" "}
          </b>
        </div>
      </div>
      <Divider align={align} type={type} />
    </Row>
  );
};

const renderDividerRow = (label, align = "top", type = "solid") => {
  return (
    <Row>
      <b className="ml-2">{label || ""}</b>
      <Divider align={align} type={type}></Divider>
    </Row>
  );
};
export default CreateTransaction;
