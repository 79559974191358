import React, { useEffect } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import companyData from "./tableCompanyData.json";
import customerData from "./tableCustomerData.json";
import {
  fetchCompany,
  fetchCustomers,
  initializeCompanyScreen,
} from "../../Redux/Actions/company";
import { connect } from "react-redux";
import CompanyComponent from "../../components/Company";
import CustomerComponent from "../../components/Customer";
import { APP_URLS } from "../../constants/urlConstants";

const ComapnyScreen = (props) => {
  const {
    history,
    fetchCompany,
    fetchCustomers,
    initializeCompanyScreen,
    showPageLevelLoader,
    companyListView,
    customerListView,
    paginationCompanyInfo,
    paginationCustomerInfo,
    isLoading,
  } = props;
  
  useEffect(() => {
    initializeCompanyScreen();
    fetchCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyListView.length > 0 && companyListView[0]?.id) {
      fetchCustomers({ id: companyListView[0].id });
    }
  }, [companyListView]);

  const createCompanys = () => {
    history.push(APP_URLS.COMPANY.ADD_COMPANY);
  };

  const loadLazyData = (props) => {
    fetchCompany(props);
  };

  const loadLazyCustomerData = (props) => {
    fetchCustomers(props);
  }

  const companyProps = {
    createCompanys,
    fetchCompany: loadLazyData,
    ...companyData,
    tableData: {
      ...companyData.tableData,
      rows: companyListView,
    },
    paginationInfo: {
      ...companyData.paginationCompanyInfo,
      pageSize: paginationCompanyInfo?.pageSize,
      pageNumber: paginationCompanyInfo?.pageNumber,
      totalPages: paginationCompanyInfo?.totalPages,
      totalRecords: paginationCompanyInfo?.totalRecords,
    },
    isLoading,
  };

  const customerProps = {
    fetchCustomers: loadLazyCustomerData,
    ...customerData,
    tableData: {
      ...customerData.tableData,
      rows: customerListView,
    },
    paginationInfo: {
      ...customerData.paginationCustomerInfo,
      pageSize: paginationCustomerInfo?.pageSize,
      pageNumber: paginationCustomerInfo?.pageNumber,
      totalPages: paginationCustomerInfo?.totalPages,
      totalRecords: paginationCustomerInfo?.totalRecords,
    },
    isLoading,
  };

  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");

  return (
    <div>
      <CompanyComponent {...companyProps} />
      <CustomerComponent {...customerProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeCompanyScreen: () => dispatch(initializeCompanyScreen()),
    fetchCompany: (payload) => dispatch(fetchCompany(payload)),
    fetchCustomers: (payload) => dispatch(fetchCustomers(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    companyListView: selectCompanyList(state.companyReducer),
    showPageLevelLoader: selectIsLoadingPage(state.companyReducer),
    paginationCompanyInfo: selectPaginationCumpanyInfo(state.companyReducer),
    paginationCustomerInfo: selectPaginationCustomerInfo(state.companyReducer),
    isPageLevelError: selectIsPageLevelError(state.companyReducer),
    isFetchingGridData: selectIsLoading(state.companyReducer),
    customerListView: selectCustomerList(state.companyReducer),
  };
};
const selectIsLoading = (companyReducer) => companyReducer.isLoading;
const selectPaginationCumpanyInfo = (companyReducer) =>
  companyReducer.paginationCompanyInfo;
const selectPaginationCustomerInfo = (companyReducer) =>
  companyReducer.paginationCustomerInfo;
const selectIsLoadingPage = (companyReducer) => companyReducer.isLoadingPage;
const selectIsPageLevelError = (companyReducer) =>
  companyReducer.isPageLevelError;
const selectCompanyList = (companyReducer) => companyReducer.companyListView;

const selectCustomerList = (companyReducer) => companyReducer.customerListView;

export default connect(mapStateToProps, mapDispatchToProps)(ComapnyScreen);
