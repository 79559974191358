import React from "react";
import { Route } from "react-router-dom";
import { APP_URLS } from "../../constants/urlConstants";
import DailyRatesScreen from "../../containers/DailyRatesScreen";

const DailyRatesRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update = false } = access;

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <DailyRatesScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
    </>
  );
};

export default DailyRatesRoutes;
