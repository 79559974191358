import React, { useEffect } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import ManageCardComponent from "../../components/Managecards";
import manageCardData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { generatePath } from "react-router";
import {
  deleteManageCardRecord,
  fetchManageCard,
  initializeManageCardScreen,
  manageCardFetchFilesByManageCardId,
  setDeleteConfirmationPopupVisibility,
  exportManageCard,
  importManageCard,
  downloadSampleCardTemplate,
} from "../../Redux/Actions/manage-card";
import { connect } from "react-redux";
import { EzToast } from "../../common/EzToast";
import { getToastProps } from "../../utils/common";

const ManageCardScreen = (props) => {
  const {
    fetchManageCard,
    history,
    initManageCardScreen,
    showPageLevelLoader,
    manageCardListView,
    isManageCardExportError,
    isManageCardExportSuccess,
    paginationInfo,
    isLoading,
    exportManageCard,
    importManageCard,
    downloadSampleCardTemplate,
    screenPermissions,
    isManageCardImportSuccess,
    isManageCardImportError,
  } = props;

  useEffect(() => {
    initManageCardScreen();
    fetchManageCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createManagecards = () => {
    history.push(APP_URLS.MANAGECARDS.ADD_MANAGECARDS);
  };
  const editManageCard = (manageCard) => {
    const id = manageCard.id;
    console.log(id);
    const path = generatePath(APP_URLS.MANAGECARDS.EDIT_MANAGECARDS, {
      id,
    });
    history.push(path);
  };

  const readManageCard = (manageCard) => {
    const id = manageCard.id;
    const path = generatePath(APP_URLS.MANAGECARDS.VIEW_MANAGECARDS, {
      id,
    });
    history.push(path);
  };

  const loadLazyData = (props) => {
    fetchManageCard(props);
  };

  const manageCardProps = {
    createManagecards,
    editManageCard,
    readManageCard,
    importManageCard,
    downloadSampleCardTemplate,
    screenPermissions,
    exportManageCard: exportManageCard,
    isManageCardExportError,
    isManageCardExportSuccess,
    fetchManageCards: loadLazyData,
    ...manageCardData,
    tableData: {
      ...manageCardData.tableData,
      rows: manageCardListView,
    },
    paginationInfo: {
      ...manageCardData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };
  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");

  return (
    <div>
      <EzToast
        {...getToastProps(
          isManageCardImportSuccess,
          isManageCardImportError,
          "Import ManageCard successfully!",
          "Error while Importing manageCard."
        )}
      />
      <ManageCardComponent {...manageCardProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initManageCardScreen: () => dispatch(initializeManageCardScreen()),
    fetchManageCard: (payload) => dispatch(fetchManageCard(payload)),
    exportManageCard: (payload) => dispatch(exportManageCard(payload)),
    deleteManageCardRecord: (payload) =>
      dispatch(deleteManageCardRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    fetchManageCardAttachmentsByManageCardId: (payload) =>
      dispatch(manageCardFetchFilesByManageCardId(payload)),
    importManageCard: (file) => dispatch(importManageCard(file)),
    downloadSampleCardTemplate: () => dispatch(downloadSampleCardTemplate()),
    // downloadAllAttachmentForManageCardId : (payload) => dispatch(downloadAllAttachmentForManageCardId(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    manageCardListView: selectManageCardsList(state.manageCardReducer),
    showPageLevelLoader: selectIsLoadingPage(state.manageCardReducer),
    paginationInfo: selectPaginationInfo(state.manageCardReducer),
    isPageLevelError: selectIsPageLevelError(state.manageCardReducer),
    isFetchingGridData: selectIsLoading(state.manageCardReducer),
    isManageCardDeletionError: selectIsManageCardDeletionError(
      state.manageCardReducer
    ),
    isManageCardDeletionSuccess: selectIsManageCardDeletionSuccess(
      state.manageCardReducer
    ),

    isManageCardExportError: selectIsManageCardExportError(
      state.manageCardReducer
    ),
    isManageCardExportSuccess: selectIsManageCardExportSuccess(
      state.manageCardReducer
    ),

    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.manageCardReducer
    ),
    isManageCardImportSuccess: selectFromManageCardReducer(
      state,
      "isManageCardImportSuccess"
    ),
    isManageCardImportError: selectFromManageCardReducer(
      state,
      "isManageCardImportError"
    ),
  };
};

const selectIsManageCardExportError = (manageCardReducer) =>
  manageCardReducer.isManageCardExportError;
const selectIsManageCardExportSuccess = (manageCardReducer) =>
  manageCardReducer.isManageCardExportSuccess;

const selectIsManageCardDeletionError = (manageCardReducer) =>
  manageCardReducer.isManageCardDeletionError;
const selectIsManageCardDeletionSuccess = (manageCardReducer) =>
  manageCardReducer.isManageCardDeletionSuccess;
const selectIsLoading = (manageCardReducer) => manageCardReducer.isLoading;
const selectShouldOpenDeleteConfirmationPopup = (manageCardReducer) =>
  manageCardReducer.shouldOpenDeleteConfirmationPopup;
const selectPaginationInfo = (manageCardReducer) =>
  manageCardReducer.paginationInfo;
const selectIsLoadingPage = (manageCardReducer) =>
  manageCardReducer.isLoadingPage;
const selectIsPageLevelError = (manageCardReducer) =>
  manageCardReducer.isPageLevelError;
const selectManageCardsList = (manageCardReducer) =>
  manageCardReducer.manageCardListView;

const selectFromManageCardReducer = (state, path) => {
  return selectManageCardReducer(state)[path];
};
const selectManageCardReducer = ({ manageCardReducer }) => {
  return manageCardReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCardScreen);
