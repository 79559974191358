import FRESHFUELS_COMPANY_LOGO_IMG from "../images/logo.svg";
import COMBINED_PETRO_COMPANY_LOGO_IMG from "../images/combined_petro.logo.png";
import CPFLEETS_COMPANY_LOGO_IMG from "../images/cpfleets.png";

const brandingConfig = {
  freshfuels: {
    name: "Fresh Fuels",
    faviconPath: "/favicon.png",
    logo: FRESHFUELS_COMPANY_LOGO_IMG,
  },
  combpetro: {
    name: "Combined Petroleum Limited",
    faviconPath: "/globe-favicon.jpg",
    logo: COMBINED_PETRO_COMPANY_LOGO_IMG,
  },
  combinepetro: {
    name: "Combined Petroleum Limited",
    faviconPath: "/globe-favicon.jpg",
    logo: COMBINED_PETRO_COMPANY_LOGO_IMG,
  },
  cpfleets: {
    name: "CP Fleets Limited",
    faviconPath: "/globe-favicon.jpg",
    logo: CPFLEETS_COMPANY_LOGO_IMG,
  },
  // Add more brands here as needed
};

export default brandingConfig;
