export const APP_URLS = {
  DASHBOARD: {
    HOME: "/Dashboard",
  },
  // ASSETS_LOCATION: {
  //   HOME: "/asset-location",
  //   HISTORY: "/asset-location/:id/history"
  // },
  // TRIPS: {
  //   HOME: '/fleet/trips',
  //   ADD_TRIPS: '/fleet/trip/add-new',
  //   EDIT_TRIPS: '/fleet/trips/:id/edit',
  // },
  // ORDERS: {
  //   HOME: '/fleet/orders',
  //   ADD_TRIPS: '/fleet/orders/add-new',
  //   EDIT_TRIPS: '/fleet/orders/:id/edit',
  // },
  // CUSTOMERS: {
  //   HOME: '/customers/customer-and-brokers',
  //   ADD_CUSTOMERS: '/customers/customer/add-new',
  //   EDIT_CUSTOMERS: '/customers/customer/:id/edit',
  // },
  // ASSETS: {
  //   HOME: '/customers/assets',
  //   ADD_ASSETS: '/customers/assets/add-new',
  //   EDIT_ASSETS: '/customers/assets/:id/edit',
  // },

  // COMPLIANCE: {
  //   HOME: '/Compliance',
  //   ADD_COMPLIANCE: '/Compliance/add-new',
  //   EDIT_COMPLIANCE: '/Compliance/:complianceId/edit',
  // },
  // INVENTORY_TRACKERS: {
  //   HOME: '/Inventory-tracker',
  //   ADD_INVENTORY_TRACKER: '/Inventory-tracker/add-new',
  //   EDIT_INVENTORY_TRACKER: '/Inventory-tracker/:inventoryTrackerId/edit',
  // },
  // EMPLOYEES: {
  //   HOME: '/Employee',
  //   ADD_EMPLOYEES: '/Employee/add-new',
  //   EDIT_EMPLOYEES: '/Employee/:id/edit',
  // },
  // REIMBURSEMENTS: {
  //   HOME: '/Reimbursement',
  //   ADD_REIMBURSEMENTS: '/Reimbursement/add-new',
  //   EDIT_REIMBURSEMENTS: '/Reimbursement/:id/edit',
  // },  
  // ACCIDENTS: {
  //   HOME: '/accidents',
  //   ADD_ACCIDENT: '/accidents/add-new',
  //   EDIT_ACCIDENT: '/accidents/:accidentId/edit'
  // },
  // INSPECTION: {
  //   HOME: '/inspections',
  //   ADD_INSPECTION: '/inspections/add-new',
  //   EDIT_INSPECTION: '/inspections/:inspectionId/edit',
  // },
  // DOCUMENT: {
  //   HOME: '/Documents',
  //   ADD_DOCUMENT:'/Documents/add-new',
  //   EDIT_DOCUMENT: '/Documents/:id/edit' //'/documents-new/:documentId/edit'
  // },
  USER_PROFILE: { HOME: "/UserProfile" },
  TRACKING_LOG: { HOME: "/tracking-logs" },
  // VAULT: {
  //   HOME: '/vault',
  //   ADD_VAULT: '/vault/add-new',
  //   EDIT_VAULT: '/vault/:vaultId/edit'
  // },
  TRANSACTIONS: {
    HOME: '/transactions',
    ADD_TRANSACTION: '/transactions/add-new',
    EDIT_TRANSACTION: '/transactions/:id/edit',
    VIEW_TRANSACTION: '/transactions/:id/view',
  },
  MANAGECARDS: {
    HOME: '/managercards',
    ADD_MANAGECARDS: '/managercards/add-new',
    EDIT_MANAGECARDS: '/managercards/:id/edit',
    VIEW_MANAGECARDS: '/managercards/:id/view',
  },
  INVOICES: {
    HOME: '/invoices',
    ADD_INVOICES: '/invoices/add-new',
    EDIT_INVOICES: '/invoices/:id/edit',
    VIEW_INVOICES: '/invoices/:id/view',
  },
  SMARTBUY: {
    HOME: '/smartbuy',
    ADD_SMARTBUY: '/smartbuy/add-new',
    EDIT_SMARTBUY: '/smartbuy/:id/edit',
  },
  TRUCKCARE: {
    HOME: '/truckcare',
    ADD_TRUCKCARE: '/truckcare/add-new',
    EDIT_TRUCKCARE: '/truckcare/:id/edit',
  },
  REPORTING: {
    HOME: '/reporting',
    ADD_REPORTING: '/reporting/add-new',
    EDIT_REPORTING: '/reporting/:id/edit',
  },
  COMPANY: {
    HOME: '/company',
    ADD_COMPANY: '/company/add-new',
    EDIT_COMPANY: '/company/:id/edit',
  },
  CUSTOMER: {
    HOME: '/customer',
    ADD_CUSTOMER: '/customer/add-new',
    EDIT_CUSTOMER: '/customer/:id/edit',
  },
  USERS: {
    HOME: '/user',
    ADD_USERS: '/user/add-new',
    EDIT_USER: '/user/:id/edit',
    VIEW_USER: '/user/:id/view'
  },
  PROFILE: {
    FETCH_USER_COMPANY_INFO: 'profile/company',
    HOME: '/profile'
  },
  DAILYRATES: {
    HOME: '/dailyrates',
    ADD_TRUCKCARE: '/dailyrates/add-new',
    EDIT_TRUCKCARE: '/dailyrates/:id/edit',
  },
};

export const API_URL = {
  TYPES: {
    FETCH_TYPE: 'types',
    VEHICLE_TYPE: 'VEHICLE_TYPE',
    EQUIP_TYPE: 'EQUIP_TYPE',
    ASSET_TRACKING_STATUS: 'ASSET_TRACKING_STATUS',
    TRACKING_DEVICE_PROVIDER: 'TRACKING_DEVICE_PROVIDER',
    TAX_TYPE: 'TAX_TYPE',
    REIMB_STATUS: 'REIMB_STATUS',
    RENTAL_STATUS: 'RENTAL_STATUS'
  },
  ACCIDENTS: {
    FETCH_ACCIDENT: 'accidents',
    FETCH_BY_ACCIDENT_ID: 'accidents/:id',
    DELETE_ACCIDENT: 'accidents',
    CREATE_ACCIDENT: 'accidents',
    EDIT_ACCIDENT: 'accidents/:id',
  },
  INVENTORY_TRACKERS: {
    FETCH_INVENTORY_TRACKERS: 'equipment-rentals',
    FETCH_BY_INVENTORY_TRACKERS_ID: 'equipment-rentals/:id',
    DELETE_INVENTORY_TRACKERS: 'equipment-rentals',
    CREATE_INVENTORY_TRACKERS: 'equipment-rentals',
    EDIT_INVENTORY_TRACKERS: 'equipment-rentals/:id',
  },
  ASSET_LOCATION: {
    FETCH_ASSET_LOCATION: 'locations',
    FETCH_BY_ASSET_LOCATION_ID: 'locations/:id',
    FETCH_ASSET_LOCATION_HISTORY: 'locations/:id/history',
    EDIT_ASSET_LOCATION: 'locations/:id',
  },
  COMPLIANCE: {
    FETCH_COMPLIANCE: 'compliances',
    FETCH_BY_COMPLIANCE_ID: 'compliances/:id',
    DELETE_COMPLIANCE: 'compliances',
    CREATE_COMPLIANCE: 'compliances',
    EDIT_COMPLIANCE: 'compliances/:id',
  },
  INSPECTIONS: {
    FETCH_INSPECTION: 'inspections',
    FETCH_BY_INSPECTION_ID: 'inspections/:id',
    DELETE_INSPECTION: 'inspections',
    CREATE_INSPECTION: 'inspections',
    EDIT_INSPECTION: 'inspections/:id',
    FETCH_INSPECTION_FILES: 'inspections'
  },
  MANAGECARDS: {
    FETCH_MANAGECARD: 'fuel-card-management',
    FETCH_BY_MANAGECARD_ID: 'fuel-card-management/:id',
    DELETE_MANAGECARD: 'fuel-card-management',
    CREATE_MANAGECARD: 'fuel-card-management',
    EDIT_MANAGECARDS: 'fuel-card-management/:id',
    EXPORT_MANAGECARD: 'fuel-card-management/export',
    IMPORT_MANAGECARD: 'fuel-card-management/upload-fuel-card-csv',
    DOWNLOAD_SAMPLE_TEMPLATE: 'fuel-card-management/download-fuel-card-csv'
  },
  USERS: {
    FETCH_USER: 'users',
    FETCH_BY_USER_ID: 'user/:id',
    DELETE_USER: 'users',
    CREATE_USER: 'users',
    EDIT_USER: 'user/:id',
    FETCH_ROLE:'roles'
  },
  TRANSACTIONS: {
    FETCH_TRANSACTIONS: 'fuel-transaction/authcode',
    FETCH_BY_TRANSACTIONS_ID: 'fuel-transaction/transactionsbyid/:id',
    DELETE_TRANSACTIONS: 'transaction',
    CREATE_TRANSACTION: 'transaction',
    EDIT_TRANSACTION: 'fuel-transaction/:id',
    IMPORT_TRANSACTIONS: 'import_transactions',
  },
  INVOICES: {
    FETCH_INVOICE: 'spol/invoices/',
    FETCH_BY_INVOICE_ID: 'spol/invoices/',
    DELETE_INVOICE: 'invoices',
    CREATE_INVOICE: 'spol/invoices/',
    EDIT_INVOICES: 'spol/invoices/update',
    DOWNLOAD_INVOICE_PDF: 'spol/invoices/:invoiceNumber/download',
    DOWNLOAD_INVOICE_CSV: 'spol/invoices/:invoiceNumber/download/csv',
    FETCH_INVOICE_STATUSES: "types?type=INVOICE_STATUS",
    EMAIL_INVOICE: "email-invoice?type=spol%2Finvoices&id=:id",
  },
  DAILY_RATES: {
    UPLOAD_DAILY_RATES: 'upload-daily-rates',
  },
  EMPLOYEES: {
    FETCH_EMPLOYEES: 'employees',
    FETCH_EMPLOYEES_BY_ID: 'employees/:id'
  },
  ASSETS: {
    FETCH_ASSETS: 'assets',
    DELETE_ASSETS: 'assets',
    CREATE_ASSETS: 'assets',
    FETCH_ASSETS_BY_ID: 'assets/:id',
    FETCH_BY_ASSET_ID: 'assets/:id',
    EDIT_ASSET: 'assets/:id',
    FETCH_ASSET_MAKE: 'asset/make',
    FETCH_ASSET_MODEL: 'asset/model',
    CREATE_ASSET_MAKE: 'asset/make',
    CREATE_ASSET_MODEL: 'asset/model',
  },
  PROVINCE: {
    FETCH_PROVINCES: 'states',
    FETCH_PROVINCES_BY_COUNTRY:'states'
  },
  COUNTRY:{
    FETCH_COUNTRY:'countries',
  },
  CITY:{
    FETCH_CITY:'city',
  },
  COUNTRY:{
    FETCH_COUNTRY:'countries',
  },
  CITY:{
    FETCH_CITY:'cities',
  },
  DOCUMENTS: {
    FETCH_DOCUMENT: 'documents',
    CREATE_DOCUMENT: 'documents',
    DELETE_DOCUMENT: 'documents',
    EDIT_DOCUMENT: 'documents/:id',
    FETCH_BY_DOCUMENT_ID: 'documents/:id',
    FETCH_DOCUMENT_TYPES: 'types?type=BS_DOC_TYPE'
  },
  CUSTOMERS: {
    FETCH_CUSTOMERS: 'customers',
    FETCH_CUSTOMER_BY_ID: 'customers/:id'
  },
  SUPPLIERS: {
    FETCH_SUPPLIERS: 'suppliers',
    FETCH_SUPPLIERS_BY_ID: 'suppliers/:id'
  },
  ASSET_TRACKING: {
    CREATE_ASSET_TRACK: 'assettrack',
    FETCH_ASSET_TRACK_ID: 'assettrack/assets/:id',
    EDIT_ASSET_TRACK_ID: 'assettrack/:id'
  },
  REIMBURSEMENTS: {
    FETCH_REIMBURSEMENT: 'reimbursements',
    CREATE_REIMBURSEMENT: 'reimbursements',
    DELETE_REIMBURSEMENT: 'reimbursements',
    EDIT_REIMBURSEMENTS: 'reimbursements/:id',
    FETCH_BY_REIMBURSEMENT_ID: 'reimbursements/:id'
  },
  FETCH_OWNER_TYPES: `types?type=OWNER_TYPE`,
  FETCH_VEHICLE_TYPE: 'types?type=VEHICLE_TYPE',
  FETCH_EQUIPMENT_TYPE: 'types?type=EQUIP_TYPE',
  FETCH_ASSET_TRACKING_STATUS: 'types?type=ASSET_TRACKING_STATUS',
  FETCH_ASSET_TRACKING_DEVICE: 'types?type=TRACKING_DEVICE_PROVIDER',
  FETCH_TRIPS: 'trips?intent=load&paged=true&page=1&size=10000',
  TRACKING_LOGS: {
    FETCH_TRACKING_LOGS: 'tracking',
  },
  VAULT: {
    FETCH_VAULT: 'vault',
    FETCH_BY_VAULT_ID: 'vault/:id',
    DELETE_VAULT: 'vault',
    CREATE_VAULT: 'vault',
    EDIT_VAULT: 'vault/:id',
  },
  PROFILE: {
    FETCH_USER_COMPANY_INFO: 'profile/company',
    FETCH_CUSTOMER_INFO: 'customers/?filter[0].field=eobId&filter[0].operator=EQUAL&filter[0].value=:id',
    FETCH_USER_INFO: 'profile/user'
  },
  SWITCH_ACCOUNT: {
    IS_ELIGIBLE_FOR_SWITCH: 'switch/active',
    FETCH_ACCOUNTS: 'switch/manage',
    SWITCH_TO_ACCOUNT_ID: 'switch/:id',
    SWITCH_BACK_TO_SELF_ACCOUNT: 'switch/manager'
  },
  LOCATION: {
    FETCH_BY_CS_NAME: 'places?search=:id&screen=:type',
    PLACE_AUTOCOMPLETE: 'places/autocomplete',
    PLACE_DETAIL: 'places/detail'
  },
  TRIPS: {
    BORDER_CONNECT: 'border/connect'
  },
  DROPDOWN: 'dropdown/',
  DROPDOWN_TYPE: {
    BS_DOC_TYPE: 'BS_DOC_TYPE',
  }
};
