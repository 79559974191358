import { Dialog } from "primereact/dialog";

const PopupDialog = ({
  visible,
  onHide,
  header,
  message,
  children,
  footer,
}) => {
  const defaultFooter = (
    <button className="btn btn-primary" onClick={onHide}>
      OK
    </button>
  );

  return (
    <Dialog
      header={header}
      visible={visible}
      onHide={onHide}
      footer={footer || defaultFooter}
    >
      {children || <p className="mt-4">{message}</p>}
    </Dialog>
  );
};

export default PopupDialog;
