import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import EzTable from "../../common/EzTable";
import EzNotFound from "../../common/EzNotFound";
const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
let SelectedRecords=[];
const Invoices = (props) => {
  const {
    tableData,
    paginationInfo,
    screenPermissions,
    createInvoices,
    readInvoice,
    editInvoice,
    emailInvoice,
    downloadInvoiceModelOpen,
    isLoading,
    fetchInvoices,
    isPageLevelError,
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: "Invoices" }
    ];
    const breadcrumbHome = { icon: "pi pi-home", url: "/" };
    return (
      <div className="make-relative">
        <div className="" style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
          <div>
           <div className="ml-3">
           <h4>Invoice</h4>
           </div>
            {/* <Button
              label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
              icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
              className={`p-button-text p-button-secondary`}
              onClick={() => {
                toggleFilterState(!areFiltersVisible);
              }}
            /> */}
          </div>
          {/* <Button
            label={"Exports Selected"}
            className={` p-button-raised`}
            style={{ borderRadius: "10px" , }}
            onClick={createInvoices}
          /> */}
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderInvoiceRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderInvoiceRecords() && !shouldRenderFullPageError();

  const handleCallback = (childData) => {
    SelectedRecords =childData;
  };

  const filters = {
    global: { value: null },
    invoice_number: { filterKey: "invoice_number", value: null },
    company_name: { filterKey: "company_name", value: null },
    issued_date: { filterKey: "issued_date", value: null },
    due_date: { filterKey: "due_date", value: null },
    start_date: { filterKey: "start_date", value: null },
    end_date: { filterKey: "end_date", value: null },
    status: { filterKey: "status", value: null },
    total_amount: { filterKey: "total_amount", value: null },
    amount_due: { filterKey: "amount_due", value: null },
    currency: { filterKey: "currency", value: null },
  };

  let isCallbackEnable =true;
  const tableProps = {
    areFiltersVisible,
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Invoice found.",
    filters,
    loading: isLoading,
    showMoreActions: false,
    onEditRecord: editInvoice,
    onReadRecord: readInvoice,
    onEmailRecord: emailInvoice,
    onDownloadRecord: downloadInvoiceModelOpen,
    loadLazyData: fetchInvoices,
    name: "invoice",
    parentCallback:handleCallback,
    isCallbackEnable
  };
  return (
    <>
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderInvoiceRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No User records to show"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Invoices;
