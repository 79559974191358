import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
import {
  TOAST,
  UPLOAD_DAILY_RATES,
} from "../../../constants/action-types/dailyRates";
import { dailyRatesService } from "../../../Services";

export const uploadDailyRatesStart = () => ({
  type: UPLOAD_DAILY_RATES.START,
});

export const uploadDailyRatesSuccess = (payload) => ({
  type: UPLOAD_DAILY_RATES.SUCCESS,
  payload,
});

export const uploadDailyRatesError = (payload) => ({
  type: UPLOAD_DAILY_RATES.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const uploadDailyRates = (payload) => {
  return (dispatch) => {
    dispatch(uploadDailyRatesStart());

    dailyRatesService
      .uploadDailyRates(payload)
      .then((response) => {
        if (!response.isError) {
          dispatch(uploadDailyRatesSuccess(response));
        } else {
          dispatch(uploadDailyRatesError(response));
        }
        setTimeout(() => {
          dispatch(resetToast());
        }, DEFAULT_TOAST_LIFE + 1000);
      })
      .catch((error) => {
        dispatch(uploadDailyRatesError(error));
      });
  };
};
