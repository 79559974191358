import { combineReducers } from "redux";
import formStateReducer from "./common/form";
import { dropDownReducer } from "./Dropdown";
import { filesAttachmentsReducer } from "./files";
import { userProfileReducer } from "../Reducers/profile";
import toastReducer from "./Notification/Toast";
import { manageCardReducer } from "./manage-card";
import { userReducer } from "./user";
import { cityReducer } from "./common/city";
import { provinceReducer } from "./common/province";
import { countryReducer } from "./common/country";
import { companyReducer } from "./company";
import { transactionReducer } from "./transactions";
import { invoiceReducer } from "./invoice";
import brandingReducer from "./brand";
import { dailyRatesReducer } from "./dailyRates";

const rootReducers = combineReducers({
  dropDownReducer,
  formStateReducer,
  filesAttachmentsReducer,
  userProfileReducer,
  toastReducer,
  manageCardReducer,
  transactionReducer,
  userReducer,
  cityReducer,
  provinceReducer,
  countryReducer,
  companyReducer,
  invoiceReducer,
  brandingReducer,
  dailyRatesReducer
});

export default rootReducers;
